<template>
  <div class="register">
    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="annual">
      <div class="w1300">

        <div class="sub_header_description txt-white page_header_space">
          <h3 class="sub_header_description_title">台灣居家醫療醫學會2020年學術研討會暨第二屆年會</h3>
          <p>今年主題著重於居家安寧病人照護實務經驗。</p>
          <p>藉由實務經驗分享，互相學習交流，讓居家醫療更能符合患者需求。</p>
        </div>

        <img class="heart_bg" src="@/statics/img/about/bg_heart.png" alt="">

      </div>
    </section>

    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="annual_info">
        <div class="information">
          <div class="title w1100">
            <h3 class="txt-bold">年會資訊</h3>
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
          <div class="w1000">

            <div class="column_list_box">

              <!-- 活動資料 -->
              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">活動資料</div>
                <div class="wrap data_location">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">活動時間</div>
                      <div class="content txt-dark_green">109 年 11月 01 日 (日) 08:30 ~ 17:00</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">報名期間</div>
                      <div class="content txt-dark_green">109 年 11月 01 日 (日) 08:30 ~ 17:00</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">活動地點</div>
                      <div class="content txt-dark_green">東海大學—外文館 (請由東大路進入)</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">報名人數</div>
                      <div class="content txt-dark_green"><span class="txt-red">32</span>/70</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col100">
                    <div class="content_group">
                      <div class="title txt-bold">餐食</div>
                      <div class="content txt-dark_green">葷餐盒、素餐盒</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col100">
                    <div class="content_group">
                      <div class="title txt-bold">活動目的</div>
                      <div class="content txt-dark_green">
                        <p>
                          1.居家醫療整合照護方案新規定<br>
                          2.醫師照護方案實務經驗分享<br>
                          3.居家吞嚥困難病人飲食處方<br>
                          4.居家吞嚥困難病人評估與處置<br>
                          5.居家病人傷口照護經驗分享<br>
                          6.安寧療護與病主法推展與困境
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">課程表</div>
                <div class="wrap">
                  <div class="col100">
                    <div class="content_group">
                      <img src="img/register/Image 14.png" alt="">
                    </div>
                  </div>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">報名資訊</div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">會員</div>
                      <div class="content txt-dark_green">1000元</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">非會員醫師</div>
                      <div class="content txt-dark_green">2500元</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">護理人員</div>
                      <div class="content txt-dark_green">1600元</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">繼續教育認證</div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">本醫學會A類積分</div>
                      <div class="content txt-dark_green">25</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">長照人員積分</div>
                      <div class="content txt-dark_green">10</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">醫事人員積分</div>
                      <div class="content txt-dark_green">10</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">護理積分</div>
                      <div class="content txt-dark_green">10</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">交通資訊</div>
                <div class="content_group">
                  <img class="annual_info_sub_map" src="img/register/CT.jpg" alt="">
                </div>
              </div>

              <!-- 附件下載 -->
              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">附件下載</div>
                <div class="content_group">
                  <a href="#" target="_blank" download="" class="file_link txt-dark_green">課程表</a>
                  <br>
                  <a href="#" target="_blank" download="" class="file_link txt-dark_green">講師資料</a>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">退費辦法</div>
                <div class="wrap">
                  <div class="col100">
                    <div class="content_group">
                      <!-- <div class="title txt-bold">會員</div> -->
                      <div class="content txt-dark_green">
                        請於開課一週前聯繫秘書處（04-24360305），
                        並E-MAIL退款帳戶存摺封面照片或影本至（loveminwoo15@yahoo.com.tw），
                        學會將扣除行政處理費200元後匯款至您的指定帳戶，逾期恕不受理退費。
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">主辦/協辦單位與聯絡資訊</div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">主辦單位</div>
                      <div class="content txt-dark_green">社團法人台灣居家醫療醫學會</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">協辦單位</div>
                      <div class="content txt-dark_green">台北市立聯合醫院</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">聯絡人</div>
                      <div class="content txt-dark_green">居家醫療學會 秘書處</div>
                    </div>
                  </div>
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">電話</div>
                      <div class="content txt-dark_green">04-24360305</div>
                    </div>
                  </div>
                </div>
                <div class="wrap">
                  <div class="col50">
                    <div class="content_group">
                      <div class="title txt-bold">E-MAIL</div>
                      <div class="content txt-dark_green">pinpinwang@yahoo.com.tw</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column_list">
                <div class="main_title h4 txt-light_green txt-bold">備註</div>
                <div class="wrap">
                  <div class="col100">
                    <div class="content_group">
                      <!-- <div class="title txt-bold">備註</div> -->
                      <div class="content txt-dark_green">
                        <p>
                        1.特邀請保健署蔡淑鈴副署長授課，歡迎各位現場聆聽。<br>
                        2.本課程含安寧乙類繼續教育 4 小時證書，並發予證書（有特約施做安寧居家者，每年必修之學分）。<br>
                        3.報名費用含中午餐點及點心。<br>
                        4.本會會員免報名費：適用於 109 年 10 月 20 日前，完成繳納 110 年度常年會費者。<br>
                        5.請於 109 年 10 月 20 日前完成報名及繳費，以利準備講義及餐點。超過日期者餐點請自備，謝謝配合。<br>
                        6.因應疫情，活動開始前需接受工作人員量測體溫，若身體不適或體溫超過37.5度請勿參加，參與人員請自備、全程配戴口罩。<br>
                        7.本活動一律採線上報名。<br>
                        8.線上報名完成後，可至[課程活動>課程活動紀錄]確認繳費狀態與查看報名資訊，若繳費失敗請重新報名。<br>
                        9.收據及上課證明，請於課程結束 5 天後至[課程活動>課程活動紀錄]下載列印，活動當天未完成簽到及簽退，則無法下載。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterAnnualSubHeader } from '@/lib/const';

export default {
  name: 'RegisterAnnualInfo',
  data() {
    return {
      ...RegisterAnnualSubHeader,
    };
  },
  components: {
    PageSubHeader,
  },
};
</script>
